<template>
  <div class="dashboard-content mt-5">
    <div class="d-flex justify-content-end aligh-items-center pb-4 pt-5">
      <div class="m-width-300 mr-2">
        <SearchBox v-model="searchedEmployees" bg-color="#D6DDE4" :rounded="5"
          placeholder="Search employees by name, date, status..." @input="runSearchData" />
      </div>
      <b-button variant="primary" class="btn py-0 mr-2" @click="addEmployee">
        Request Talent
      </b-button>
    </div>

    <div class="employee-table m-rounded-10 mb-5 py-2">
      <div v-if="employees.length">
        <b-table responsive striped borderless :fields="fields" :items="filteredEmployees ? filteredEmployees : employees"
          :per-page="perPage" :current-page="currentPage">
          <template #cell(details)="data">
            <div role="button" @click="showDetails(data.item.id)">
              <EyeIcon />
            </div>
          </template>

          <template #head(details)>
            <span class="d-none"></span>
          </template>

          <template #head(availability_readable)>
            <span>
              Availability
            </span>
          </template>
        </b-table>

        <div class="m-pagination overflow-auto d-flex p-4 justify-cotnent-between align-items-center">
          <span class="flex-grow-1">
            Showing page {{ currentPage }} of {{ pages }}
          </span>
          <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows" align="right" pills>
          </b-pagination>
        </div>
      </div>

      <div v-else class="py-5 d-flex flex-colum justify-content-center ">
        <!-- <div class="">
          <EmptyIcon />
        </div> -->
        <p class="mt-3">No Employees available</p>
      </div>
    </div>
    <RequestTalentModal :projects="projects" :hiring-type="hiring_type" :processing="processing" @submit="requestTalent" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SearchBox from "../../components/SearchBox.vue";
import EyeIcon from "../../components/svg/EyeIcon.vue";
import RequestTalentModal from "@/components/employee/RequestTalentModal.vue"
import experienceData from '@/data/experience.json';
import skillData from '@/data/skills.json';
import EmptyIcon from "@/components/svg/EmptyIcon.vue";

export default {
  components: {
    SearchBox,
    RequestTalentModal,
    EyeIcon,
    // EmptyIcon
},

  data() {
    return {
      fields: ["name", "primary_skill", "years_of_experience", "availability_readable", "details"],
      borderless: true,
      currentPage: 1,
      perPage: 7,
      experience: experienceData.experience,
      slides: skillData.slides,
      searchedEmployees: '',
      filteredEmployees: this.employees,
      processing: false,
      status: {
        0: "Unavailable",
        1: "Available"
      },
      statusVariant: {
        1: 'text-warning',
        2: 'text-primary',
        3: 'text-secondary',
        4: 'text-success'
      },
      payload: {},
      options: [
        {
          title: "View Details",
          route: "dashboard/employees/details",
          class: ["text-tertiary"],
        },
      ],
      hiring_type: {
        "Full time": 1,
        "Part time": 2
      }
    };
  },
  computed: {
    totalRows() {
      return this.employees.length;
    },
    pages() {
      return  Math.ceil(this.employees?.length/this.perPage)
    },
    getVariant(status) {
      return this.statusVariant[status];
    },
    ...mapState({
      projects: (state) => state.projects.projects,
      employees: (state) => state.employees.employees
    })
  },
  created() {
    this.fetchProjects();
    this.fetchEmployees();

  },
  methods: {
    ...mapActions({
      fetchProjects: "projects/fetchProjects",
      fetchEmployees: "employees/fetchEmployees",
    }),
    addEmployee() {
      this.$bvModal.show('request_talent')
    },
    requestTalent(form) {
      this.processing = true
      this.payload = form

      this.$store
        .dispatch("employees/requestTalent", this.payload)
        .then(() => {
          this.$bvModal.hide('request_talent')
        })
        .catch((err) => {
          let data = err?.response;
          if (data?.status === 422) {
            this.errors = this.getErrors(data.data.errors);
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    showDetails(id) {
      this.$router.push(`employees/${id}`)
    },
    runSearchData() {
      if (this.searchedEmployees !== '') {
        this.filteredEmployees =
          this.employees.filter(
            (employee) =>
              employee.name.toLowerCase().includes(this.searchedEmployees.toLowerCase())
          )
      }
      if (this.searchedEmployees == '') {
        this.filteredEmployees = this.employees
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-table {
  background-color: $white;
}
</style>
